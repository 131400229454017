<!-- 编辑团队或者个人的timesheet 组件 -->
<template>
	<v-card tile>
		<v-toolbar dense dark color="primary" elevation="0">
			<v-app-bar-nav-icon>
				<v-btn icon @click="$emit('on-close')"><v-icon>mdi-close</v-icon></v-btn>
			</v-app-bar-nav-icon>
			<v-toolbar-title>
				编辑工人工作记录 
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<!--- 过滤功能 暂时disable 
			<v-btn v-if="isBulkEdit" icon @click="dlgWorkerFilter = true"><v-icon>mdi-filter-variant</v-icon></v-btn>--->
		</v-toolbar>
		<v-divider></v-divider>
		<v-card-text class="px-0 py-4">
			<div class="d-flex flex-column" v-for="worker in workers" :key="worker.id" >
				<worker-timesheet-entry-card
					v-if="showWorkerCard"
					:worker="worker"
					:workTypes="getWorkTypeListByUserRole(worker.user.role)"
					:bulkdMode="isBulkEdit"
				/>
				<v-divider class="mb-6" v-if="showWorkerCard"></v-divider>
			</div>
		</v-card-text>
		<v-footer fixed class="py-2">
			<v-row>
				<v-col v-if="isBulkEdit">
					<v-btn color="info" block @click="onBulkAddTimesheet">						
						<v-icon left>mdi-plus</v-icon>添加批量记录
					</v-btn>
				</v-col>
				<v-col>
					<v-btn color="success" block @click="onSaveTimesheet">
						<v-icon left>mdi-content-save</v-icon>保存记录
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
		<!--- Only show when bulk edit -->
		<v-dialog v-if="isBulkEdit" v-model="dlgBulkEditTimesheet">
			<v-card>
				<v-card-title>批量添加工作记录
					<v-spacer></v-spacer>

					<v-btn icon @click="dlgBulkEditTimesheet = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="px-4 py-4">
					<div class="d-flex align-start px-4" v-for="(activity, i) in activityTemplate" :key="i">
						<div class="d-flex flex-column flex-grow-1">
							<div class="d-flex align-center py-2">
								<v-select
									v-model="activity.work_type"
									dense
									outlined
									hide-details
									:items="allWorkTypeList"
									item-text="name"
									item-value="id"
									return-object
									label="工作类别"
									class="mr-3"
								>
								</v-select>

								<v-text-field
									v-model="activity.duration"
									dense
									outlined
									type="number"
									hide-details
									label="工时(小时)"
									required
								></v-text-field>
							</div>
							<div class="d-flex py-2">
								<v-textarea
									v-model="activity.notes"
									dense
									auto-grow
									rows="4"
									row-height="15"
									value="22"
									outlined
									hide-details
									label="工作记录">
								</v-textarea>
							</div>
						</div>				
						<v-btn
							class="ml-2"
							dark
							color="error"
							icon
							@click="removeActivity(i)"
							>
							<v-icon small>mdi-trash-can-outline</v-icon>
						</v-btn>
					</div>
					<div class="d-flex justify-center"><v-btn color="info" text @click="addNewRecord"><v-icon>mdi-plus</v-icon>添加新记录</v-btn></div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" elevation="0" @click="applyBulkTemplate">批量添加</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--- Filter dialog 暂时disable
		<v-dialog v-if="isBulkEdit" v-model="dlgWorkerFilter" persistent>
			<v-card>
				<v-card-title><v-icon class="mr-2">mdi-filter-variant</v-icon>过滤工人数据</v-card-title>
				<v-divider></v-divider>
				<v-subheader>通过用户角色过滤</v-subheader>
					<v-card-text>
						<v-chip-group
							active-class="info--text"
							column
						>
							<v-chip outlined value="clockInOnly">只显示管理员</v-chip>
							<v-chip outlined value="allCompleted">只显示普通工人</v-chip>
							<v-chip outlined value="needClockIn">只显示包活工人</v-chip>
							<v-chip outlined value="needClockIn">只显全部</v-chip>
						</v-chip-group>
					</v-card-text>
				<v-divider></v-divider>
				<v-subheader>选择显示的用户</v-subheader>
					<v-list dense class="mt-0">
						<v-list-item v-for="worker in workers" :key="worker.id">
							<v-list-item-avatar>
								<v-avatar
									color="primary"
									size="32"
								>
								<span class="white--text body-2">{{ worker.user.name[0] }}</span>
							</v-avatar>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-text="worker.user.name"></v-list-item-title>
								<v-list-item-subtitle v-text="worker.user.role"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-checkbox v-model="worker.visible"></v-checkbox>
							</v-list-item-action>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="info" elevation="0" @click="dlgWorkerFilter = false">过滤工人</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> --->
	</v-card>
</template>
 
<script>
import workerTimesheetEntryCard from './workerTimesheetEntryCard.vue'

import ProjectServices from '../../services/Project';
import TimesheetService from '../../services/Timesheet';

export default {
	name: 'timesheetForm',

	data: () => ({
		workTypes: [],
		dlgBulkEditTimesheet: false,
		activityTemplate: [],
		contractorWorkTypeList:[],
		workerWorkTypeList: [],
		allWorkTypeList: [],
		dlgWorkerFilter: false
	}),
	props: {
		workers: {
			type: Array,
			required: true
		},
		projectId: {
			type: Number,
			required: true
		},
		isBulkEdit: {
			type: Boolean,
			default: false
		}
	},
	components: {
		workerTimesheetEntryCard
	},
	created(){
		this.workers.forEach((worker)=>{
			worker.visible = true;
			worker.user.role.startsWith('天工')
		})
		this.getWorkTypeList();
	},
	methods: {
		async getWorkTypeList(){
			try {
				let response = await ProjectServices.listAvailableWorkTypes(this.projectId);
				if(response.status === 200){
						response.data.data.forEach(workType=>{
							if (workType.name.startsWith('天工')){
								this.workerWorkTypeList.push(workType)
							}
							if (workType.name.startsWith('包活')){
								this.contractorWorkTypeList.push(workType)
							}
							this.allWorkTypeList.push(workType);
						})
				}
			} catch(error){
				console.log(error)
			}
		},
		onSaveTimesheet(){
			this.workers.forEach((worker)=>{
				worker.activities.forEach((activity, i)=>{
					let body ={
						type: worker.user.role === 'worker' ? "day_labour"  : 'package',
						work_type_id: activity.work_type.id,
						duration: (parseFloat(activity.duration) * 60).toFixed(0),
						notes: activity.notes === null ? '' : activity.notes
					}
					let groupMemberId = worker.id;
					let numberOfRequests = worker.activities.length
					let isBulkRequestCompleted = Number(numberOfRequests) ===  Number(i+1)
					if(activity.id){
						//update 
						console.log('update')
						this.updateTimesheetChange(groupMemberId, activity.id, body, isBulkRequestCompleted)
					} else {
						//add new
						console.log('new')
						this.postTimesheetChange(groupMemberId, body, activity, isBulkRequestCompleted)
					}
					
				})
			})
		},
		async updateTimesheetChange(groupMemberId, activityId, body, isCompleted){
			try {
				let response = await TimesheetService.editActivity(groupMemberId, activityId, body);
				if(response.status === 200){
					//emit saving success toast
					if(isCompleted){
						activityId = response.data.data.id;
						this.$emit('on-update-success', {	message: `工作记录更新成功！`, type: 'success'})
					}
				}
			} catch(error){
				console.log(error)
			}
		},
		async postTimesheetChange(groupMemberId, body, activity, isCompleted) {
			try {
				let response = await TimesheetService.createActivity(groupMemberId, body);
				if(response.status === 200){
					//emit saving success toast
					if(isCompleted){
						activity.id = response.data.data.id;
						this.$emit('on-update-success', {	message: `工作记录更新成功！`, type: 'success'})
					}
				}
			} catch(error){
				console.log(error)
			}
		},
		onBulkAddTimesheet() {
			this.dlgBulkEditTimesheet = true;
			console.log(this.workers)
		},
		addNewRecord() {
			const activity = {
				work_type: {
					id: this.activityTemplate.length + 1,
					name: null
				},
				duration: null,
				notes: null
			}
			this.activityTemplate.push(activity)
		},
		removeActivity(i){
			this.activityTemplate.splice(i, 1)
		},
		showWorkerCard(worker) {
			if (!this.isBulkEdit) {
				return true;
			} else {
				return worker.visible
			}
		},
		getWorkTypeListByUserRole(role){
			if(role === 'worker'){
				return this.workerWorkTypeList
			} else if(role === 'contractor'){
				return this.contractorWorkTypeList
			} else {
				return this.allWorkTypeList
			}
		},
		applyBulkTemplate(){
			this.workers.forEach((worker)=>{
				let filtered_activities = this.applyActivityTemplateToWorker(worker)
				filtered_activities.forEach(activity=>{
					worker.activities.push(activity)
				})
			})
			//clear template
			this.dlgBulkEditTimesheet = false
			this.activityTemplate = []
		},
		applyActivityTemplateToWorker(worker) {
			if(worker.user.role === 'worker'){
				return this.activityTemplate.filter((activity)=>{
					return activity.work_type.name !== null && !activity.work_type.name.startsWith('包活') && activity.work_type.name
				})
			} else if(worker.user.role === 'contractor'){
				return this.activityTemplate.filter((activity)=>{
					return  activity.work_type.name !== null && !activity.work_type.name.startsWith('天工') && activity.work_type.name
				})
			} else {
				return this.activityTemplate
			}
		}

	}
}
 </script>
 
 <style>
 
 </style>