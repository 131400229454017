var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","dense":"","elevation":"0","color":"primary"}},[_c('v-toolbar-title',[_vm._v("添加工人到项目")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v(" 关闭 ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 pt-0 pb-8"},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{staticClass:"mx-1 my-2",attrs:{"label":"搜索项目工人","outlined":"","dense":"","hide-details":"","clearable":"","clear-icon":"mdi-close","prepend-inner-icon":"mdi-magnify","placeholder":"输入项目工人名称"},on:{"click:clear":_vm.clearWorkerSearch,"input":_vm.onSearchWorker},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-divider'),(_vm.loading)?[_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-8 py-8"},[_c('v-progress-circular',{attrs:{"size":140,"width":2,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"body-1 mt-4"},[_vm._v("载入数据中")])],1)]:[(_vm.search && _vm.search.length)?[(_vm.searchResult.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.searchResult,"item-key":"name","footer-props":{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}},scopedSlots:_vm._u([{key:"item.actions",fn:function({item}){return [_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addWorkerToProjectTimesheet(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)]}}],null,false,3201978947)})]:[_c('div',{staticClass:"body-2 text-center py-8"},[_vm._v("无法找到符合关键字"),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v("的结果")])]]:[(_vm.workers.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.workers,"item-key":"name","footer-props":{
								'items-per-page-text': '每页显示',
								//'items-per-page-options': items
							}},scopedSlots:_vm._u([{key:"item.actions",fn:function({item}){return [_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0"},on:{"click":function($event){return _vm.addWorkerToProjectTimesheet(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)]}}],null,false,3201978947)})]:[_c('div',{staticClass:"body-2 text-center py-8"},[_vm._v("没有工人在这个项目中，请在项目设置中添加工人")])]]]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }