<template>
	<div>
		<v-btn color="info"
			elevation="0"
			block
			@click="startCamera"><v-icon left>mdi-face-recognition</v-icon> 开启面部打卡模式
		</v-btn>
		<v-dialog v-model="dlgFace" fullscreen>
			<v-card tile style="background-color: #eeeeee; padding-top: 120px">
				<div class="pa-12">
					<div class="text-h6 text-center"><v-icon left>mdi-face-recognition</v-icon>面部识别打卡</div>
					<div class="subtitle-1 mt-6 text-center">请确保面部再摄像框中, 不要移动</div>
				</div>
				
				<div class="body-1 text-center my-3" v-text="progressText"></div>
				<div v-if="loading" class="pa-6 text-center mb-8">
					<v-progress-circular
						indeterminate
						size="120"
						color="primary"
					></v-progress-circular>
				</div>
				<div v-else-if="captured">
					<div v-if="photoData" class="face-detection">
						<v-overlay absolute>
							<v-progress-circular
								indeterminate
								size="120"
								color="#fff"
							></v-progress-circular>
						</v-overlay>
						<img :src="photoData" class="capturedPhoto" alt="Captured Photo" width="620" />
					</div>
				</div>
				<div v-else class="face-detection">
					<video ref="video" autoplay playsinline width="620" height="460"></video>
					<canvas ref="canvas" width="620" height="460"></canvas>
				</div>
				<div class="d-flex flex-row justify-center">
					 <v-btn color="info" elevation="0" @click="onExitFaceDetection">退出面部识别打卡</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
  </template>
  
  <script>
  	import * as faceapi from 'face-api.js';
	import axios from 'axios';
	import UtilServices from '../../services/Utils';
	import UserServices from '../../services/User';
  
  export default {
	name: 'faceRecognition',
	data() {
	  return {
		dlgFace: false,
		videoStream: null,
		modelsLoaded: false,
		lastDetection: null,
		detectionStartTime: null,
		faceStable: false,
		captured: false,
		loading: true,
		photoData: null,
		key: null,
		presignedUrl: null,
		progressText: '正在载入面部识别功能，请稍等。',
		capturedInterval: null,
		foundUser: null,
	  };
	},
	methods: {
	  	async startCamera() {
			this.dlgFace = true;
			if (!this.modelsLoaded) {
				await this.loadModels();
			}
			this.initCamera();
		},
		async initCamera() {
			this.videoStream = await navigator.mediaDevices.getUserMedia({ video: {} });
			this.$refs.video.srcObject = this.videoStream;
			this.detectFaceInCamera();
		},
		async loadModels() {
			try {
				// Load models from the 'public/models' folder
				await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
				await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
				await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
				await faceapi.nets.ssdMobilenetv1.loadFromUri('/models'); // Ensure SSD MobileNet is also loaded
				this.modelsLoaded = true;
				this.loading = false;
				this.progressText = ''

				this.$emit('on-load-success', '面部识别模块加载成功');
			} catch (error) {
				this.$emit('on-load-error', '面部识别模块加载失败');
			}
		},
		async detectFaceInCamera() {
			const video = this.$refs.video;
			const canvas = this.$refs.canvas;
			const displaySize = { width: video.width, height: video.height };
			faceapi.matchDimensions(canvas, displaySize);

			this.capturedInterval = setInterval(async () => {
				const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

				const resizedDetections = faceapi.resizeResults(detections, displaySize);

				const ctx = canvas.getContext('2d');
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				faceapi.draw.drawDetections(canvas, resizedDetections);
				faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

				this.checkFaceStability(resizedDetections);
			}, 100);
		
		},
		checkFaceStability(detections) {
			if (detections.length === 1) {
				const currentDetection = detections[0].detection.box;

				if (this.lastDetection) {
				const distance = Math.sqrt(
					Math.pow(currentDetection.x - this.lastDetection.x, 2) +
					Math.pow(currentDetection.y - this.lastDetection.y, 2)
				);

				if (distance < 10) {
					if (!this.detectionStartTime) {
					this.detectionStartTime = Date.now();
					} else if (Date.now() - this.detectionStartTime >= 2000) {
					this.faceStable = true;
					this.capturePhoto();
					}
				} else {
					this.detectionStartTime = null;
					this.faceStable = false;
				}
				}
				this.lastDetection = currentDetection;
			} else {
				this.resetCapture(); // Reset capture when no face is detected
			}
		},
		async capturePhoto() {
			if (this.captured) return; // Prevent multiple captures in a row

			const video = this.$refs.video;
			const canvas = this.$refs.canvas;
			const ctx = canvas.getContext('2d');

			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
			
            this.photoData = canvas.toDataURL('image/png');
	
			this.captured = true;
			this.progressText = '正在处理面部信息，请稍等...'
			this.getPreSignnedURL()
		},
	
		resetCapture() {
			if (this.captured) {
				clearInterval(this.capturedInterval);
				this.captured = false;
				this.loading = true;
				this.progressText = '';
				this.detectionStartTime = null;
				this.lastDetection = null;
				this.faceStable = false;
				this.photoData = null;
				this.presignedUrl = null;
				this.key = null;

				setTimeout(()=>{
					this.initCamera()
					this.loading = false;
				},1200 )
				
			}
		},
		async getPreSignnedURL(){
			try {
				const payload = {
				    type: 'face_identification',
    				file_name: `project_face_identification_.jpg`
				}
				const response = await UtilServices.getS3AssignedUrl(payload);
				
				if(response.data.statusCode === 200){
					this.progressText = '与数据库比对图片用户信息中...'
					this.key = response.data.data.key;
					this.presignedUrl = response.data.data.presignedUrl;
					this.uploadPhoto()
				}

			} catch(error){
				this.$emit('on-load-error', '面部图片处理失败。');
				this.resetCapture()
			}
		},
		base64ToBuffer(base64) {
			const base64String = base64.split(',')[1];
			const binaryString = atob(base64String);
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return bytes;
		},
		uploadPhoto(){
			if(!this.presignedUrl ){
				return;
			}
			const buffer = this.base64ToBuffer(this.photoData);
			this.uploadToS3(buffer, this.presignedUrl , 'image/jpeg');
		},
		async uploadToS3(buffer, url, fileType) {

			const requestOptions = {
				headers: { 'Content-Type': fileType },
				body: JSON.stringify(buffer)
			};
			try {
				const response = await axios.put(url, buffer, {
						headers: {
						'Content-Type': fileType }
				})

				if(response.status === 200){
					this.verifyUserByFaceImage()
				}
			} catch(error){
				this.$emit('on-load-error', '面部图片处理失败。');
				this.resetCapture()
			}
		},
		onExitFaceDetection(){
			this.dlgFace = false;
			location.reload()
		},
		async verifyUserByFaceImage() {
			try {
				const response = await UserServices.verifyUserByImage(this.key)

				if(response.status === 200){
					this.$emit('on-user-found', response.data.data.user);
				} 
				if (response.status === 404){
					this.$emit('on-user-notfound', '很抱歉，我们无法匹配工人，请确定工人已经被添加入项目中。');
				}

			} catch(error) {
				this.$emit('on-load-error', '面部比对失败。');
				this.resetCapture()
			}
		},
	},
	async mouted() {
		await this.loadModels(); // Load models when the component is mounted
	}
  };
  </script>
  
  <style scoped>
  .face-detection {
	position: relative;
	height: 460px;
	display: block;
	margin: 0px auto 48px auto;
	overflow: hidden;
    border-radius: 50%;
	@media only screen and (min-width: 620px) {
		width: 460px;
	}

	@media only screen and (max-width: 620px) {
		width: 85%;
		border-radius: 20px;
	}
  }
  video {
	z-index: 9;
	position: absolute;
	top: 0;
	left: -80px;
	width: 620px;
	height: 460px;
  }
  canvas{
	z-index: 99;
	position: absolute;
	top: 0;
	left: -80px;
	width: 620px;
	height: 460px;
  }
  .capturedPhoto {
	position: absolute;
	top: 0;
	left: -80px;
	width: 620px;
	height: 460px;
  }
  </style>
  