<template>
	<v-sheet v-if="group_id != null">
		<v-toolbar flat>
			<v-toolbar-title v-text="displayTimesheetDate">
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn text color="primary" small @click="$router.go(-1)">返回上页</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-container v-if="project">
			<v-row :align="'start'" :justify="'space-between'">
				<v-col lg="9" md="8" sm="8">
					<div class="d-flex px-1 py-2 align-center">
						<div class="subtitle-2 mr-2" style="width:140px">项目名称:</div>
						<v-chip label :to="{ name: 'viewProject', params: {id:project.id}}">{{project.name}} <v-icon small right>mdi-open-in-new</v-icon></v-chip> 
					</div>
					<div class="d-flex px-1 py-2 align-center">
						<div class="subtitle-2 mr-2" style="width:140px">建筑商:</div>
						<div class="body-2 pl-3">{{project.builder_name}}</div>
					</div>
					<div class="d-flex px-1 py-2 align-center">
						<div class="subtitle-2 mr-2" style="width:140px">工作记录状态:</div>
						<div class="body-2">
							<template v-if="timesheet_status === 0">
								<v-chip
									color="warn"
									>
									待递交
								</v-chip>
							</template>
							<template v-else-if="timesheet_status === 1">
								<v-chip
									color="info"
									>
									待审核
								</v-chip>
							</template>
							<template v-else>
								<v-chip
									color="success"
									>
									已审核
								</v-chip>
							</template>
						</div>
					</div>
				</v-col>
				<v-col align-self="start" lg="3" md="4" sm="4" class="mb-4">
					<div class="d-flex flex-column justify-space-between" style="height: 130px">
					<v-btn
						:color="site_manager_mode ? 'info' : 'success'"
						elevation="0"
						rounded
						@click="onChangeMode"
					>
						<template v-if="site_manager_mode">
							<v-icon left>mdi-clock-plus-outline</v-icon> 切换到打卡模式
						</template>
						<template v-else>
							<v-icon left>mdi-swap-horizontal</v-icon> 切换到工头模式
						</template>
					</v-btn>
					<face-recognition
						ref="faceRecognition"
						@on-load-success="popToast({message: $event, type: 'success'}, 100)"
						@on-load-error="popToast({message: $event, type: 'error'}, 100)"
						@on-user-notfound= "onUserNotFound($event)"
						@on-user-found= "checkUserStatus($event)"
					/>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-container class="pa-0" style="margin-bottom:140px;">
				<v-divider></v-divider>

				<div v-if="site_manager_mode" class="yellow darken-3 text-center pa-2 ma-3">
					<div class="text-center body-2 white--text"><v-icon left small class="white--text">mdi-alert</v-icon>您正在使用的工头模式编辑工作记录</div>
				 </div>
				<div class="py-3 d-flex flex-column">
					<div class="subtitle-1  px-3">当日工作团队数据</div>
					<div class="px-3 mt-4 mb-3 d-flex" v-if="teams && teams.length">
						<div class="body-2 ">
							<div><span class="caption mr-3">当日天工总人数:</span> <strong>  {{getDayLabourNumberTotal}}</strong> </div>
							<div><span class="caption mr-3">当日包工总人数:</span> <strong>  {{getDayPackageNumberTotal}}</strong></div>
							<v-divider class="my-2"></v-divider>
							<div><span class="caption mr-3">当日工作总人数:</span> <strong>  {{ parseInt(getDayLabourNumberTotal) + parseInt(getDayPackageNumberTotal) }}</strong></div>
						</div>
						<v-divider class="mx-4" vertical></v-divider>
						<div class="body-2 ">
							<div><span class="caption mr-3">当日天工总小时:</span> <strong>  {{getDayLabourHourTotal}}</strong> </div>
							<div><span class="caption mr-3">当日包工总小时:</span> <strong>  {{getDayPackageHoursrTotal}}</strong></div>
							<v-divider class="my-2"></v-divider>
							<div><span class="caption mr-3">当日工作总小时:</span> <strong>  {{ parseFloat(getDayLabourHourTotal) + parseFloat(getDayPackageHoursrTotal) }}</strong></div>
						</div>
					</div>
				</div>
				<!-- 新的UI界面 -->
				 <v-divider></v-divider>
				 
				 <v-container>
					<v-row :align="'center'" :justify="'space-between'">
						<v-col><div class="d-flex align-center" style="height: 100%;"><v-icon class="mr-1">mdi-star-circle</v-icon><div class="body-1">工作记录</div></div></v-col>
						<v-col>
							<v-text-field
							label="搜索工人"
							v-model="search"
							outlined
							dense
							hide-details
							clearable
							clear-icon="mdi-close"
							prepend-inner-icon="mdi-magnify"
							@click:clear="clearWorkerSearch"
							@input="onSearchWorker"
							placeholder="输入工人姓名"
						></v-text-field>
						</v-col>
					</v-row>
				 </v-container>
				<!-- 打开批量编辑模式 -->
				<template v-if="site_manager_mode && timesheet_status === 0">
				<v-divider></v-divider>
					<div class="d-flex space-between align-center px-2 mt-2" style="width: 100%;">
						<div>
							<div v-if="isBulkEditMode" class="body-2">
								<v-chip
									v-if="selected_workers_for_edit.length"
					
									color="green"
									outlined
									>选择了 
									{{ selected_workers_for_edit.length }} 
									个工人
								</v-chip>
								<template v-else>
									<v-btn  color="blue" text><v-icon small left>mdi-information</v-icon>请选择要批量编辑的工人</v-btn>
								</template>
								<!--- 
								<template v-if="selected_workers_for_edit.length">
									<v-btn color="info" small elevation="0" class="ml-3" @click="bulkEditWorkerTimesheet"><v-icon small left>mdi-timer-edit</v-icon>批量编辑工时</v-btn>
								</template>
								--->
								<template v-if="selected_workers_for_edit.length">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
											color="info"
											outlined
											elevation="0"
											v-bind="attrs"
											v-on="on"
											
											class="ml-4"
											>
											<v-icon small>mdi-gesture-tap-button</v-icon>
											<span class="ml-2 mr-1">
												选择批量功能
											</span>
											<v-icon small>mdi-chevron-down</v-icon>
											</v-btn>
										</template>
										<v-list dense>
											<v-list-item @click="bulkClockOut">
												<v-list-item-subtitle><v-icon small class="mr-1">mdi-clock-minus-outline</v-icon> 批量 Clock Out</v-list-item-subtitle>
											</v-list-item>
											<v-list-item  @click="bulkClockIn">
												<v-list-item-subtitle><v-icon small class="mr-1">mdi-clock-plus-outline</v-icon> 批量 Clock In</v-list-item-subtitle>
											</v-list-item>
											<v-list-item  @click="bulkRemoveWorker">
												<v-list-item-subtitle><v-icon small class="mr-1">mdi-delete-sweep</v-icon> 批量删除</v-list-item-subtitle>
											</v-list-item>
											<v-list-item  @click="bulkEditWorkerTimesheet">
												<v-list-item-subtitle><v-icon small class="mr-1">mdi-delete-sweep</v-icon> 批量编辑工时</v-list-item-subtitle>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
							</div>
							<div v-else class="d-flex">
								<template v-if="!search">
									<v-icon small left>mdi-filter-multiple-outline</v-icon>
									<v-chip-group
										active-class="info--text"
										column
										v-model="filter"
										@change="showFilter"
									>
										<v-chip outlined value="clockInOnly">显示已经 Clock In 的工人</v-chip>
										<v-chip outlined value="allCompleted">显示已完成打卡的工人</v-chip>
										<v-chip outlined value="needClockIn">显示尚未 Clock In 的工人</v-chip>
									</v-chip-group>
								</template>
							</div>
						</div>
						<v-spacer></v-spacer>
						<v-btn color="primary"  text @click="bulkEdit"><v-icon small left>mdi-pencil-box-multiple-outline</v-icon>
							{{isBulkEditMode ? '退出批量编辑' : '批量编辑'}}
						</v-btn>
					</div>
				</template>
				<!--- 正常显示所有工人 --->
				 <template v-if="teams_timeheet.length && !search && filter === undefined" >
					<v-card class="ma-2" v-for="(team, i) in teams_timeheet" :key="i" outlined elevation="0">
						<div class="d-flex align-center space-between">	
							<v-card-subtitle class="black--text d-flex align-center flex-start">
								<!---
								<template v-if="isBulkEditMode">
									<v-checkbox class="ma-0" :disabled="team.members.length === 0" dense hide-details @change="onGroupSelected(team.members)"  :label="team.name === 'ungrouped' ? '无分组工人': team.name"></v-checkbox>
								</template>
								<template v-else>
									{{team.name === 'ungrouped' ? '无分组工人': team.name }}
								</template> --->

								{{team.name === 'ungrouped' ? '无分组工人': team.name }}
							</v-card-subtitle>
							<v-spacer></v-spacer>
							<v-btn v-if="site_manager_mode && !isBulkEditMode && timesheet_status === 0" icon class="mr-2" :color="team.members.length ? 'amber lighten-1' : 'error'" @click="onDeleteGroup(team)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
						</div>
						<v-divider></v-divider>
						<v-card-text class="pa-2 grey lighten-4">
							<div class="d-flex flex-wrap" style="gap: 8px" v-if="team.members.length">
								<worker-card
									v-for="worker in team.members"
									:key="worker.id"
									:worker="worker"
									:canEditTimesheet="timesheet_status === 0"
									:clocked_in="isWorkerClockedIn(worker.clock_in_outs)"
									:mode="site_manager_mode"
									:canselect="isBulkEditMode"
									:selectedWorkers="selected_workers_for_edit"
									@on-clock-out="onWorkerClockOut($event)"
									@on-clock-in="onWorkerClockIn($event)"
									@on-remove-worker="onWorkerRemove($event)"
									@on-edit-timesheet="onEditWorkerTimesheet($event)"
									@on-worker-selection-change="onWorkerSelectionChange($event)"
								/>
							</div>
							<template v-else>
								<div class="pa-4 text-center">
									您还没有添加任何工作人员在这个组里
								</div>
							</template>
						</v-card-text>
					</v-card>
				 </template>
		
				 <!--- 搜索结果和过滤结果 UI--->
				 <template v-if="(search && search.length ) || filter !== undefined">
						<v-card class="ma-2" outlined elevation="0">
						<div class="d-flex align-center space-between">	
							<v-card-subtitle class="black--text d-flex align-center flex-start">
								<template v-if="filter !== undefined && search && search.length === 0">
									过滤工人结果:
								</template>
								<template v-else>
									工人搜索结果:
								</template>
							</v-card-subtitle>
							<v-spacer></v-spacer>
						</div>
						<v-divider></v-divider>

						<v-card-text class="pa-2 grey lighten-4">
							<template v-if="searchResult && searchResult.length">
								<div class="d-flex flex-wrap" style="gap: 8px" >
									<worker-card
										v-for="worker in searchResult"
										:key="worker.id"
										:worker="worker"
										:canEditTimesheet="timesheet_status === 0"
										:clocked_in="isWorkerClockedIn(worker.clock_in_outs)"
										:mode="site_manager_mode"
										:canselect="isBulkEditMode"
										:selectedWorkers="selected_workers_for_edit"
										@on-clock-out="onWorkerClockOut($event)"
										@on-clock-in="onWorkerClockIn($event)"
										@on-remove-worker="onWorkerRemove($event)"
										@on-edit-timesheet="onEditWorkerTimesheet($event)"
										@on-worker-selection-change="onWorkerSelectionChange($event)"
									/>
								</div>
							</template>
							<template v-else>
								<div class="pa-8 ma-3 text-center">
									很抱歉，我们无法找到包含关键字 <strong>{{ search }} </strong> 的工人
								</div>
							</template>
						</v-card-text>
					</v-card>
				 </template>
			</v-container>
		</template>
	
		<!--- Add Team Fab Button ---->
		<v-container class="fixedSaveButton" v-if="canUserEditTimesheet">
			<v-divider class="mb-3"></v-divider>
			<div class="d-flex justify-space-between">
				<div v-if="user && user.role === 'admin' && timesheet_status === 1">
					<v-btn
						elevation="0"
						small
						color="primary"
						@click="updateTimesheet(2)"
						>
						<v-icon small left>mdi-briefcase-upload-outline</v-icon>批准工作记录
					</v-btn>
				</div>
				<div v-else-if="timesheet_status === 0">
					<v-btn
						elevation="0"
						small
						color="primary"
						@click="updateTimesheet(1)"
						>
						<v-icon small left>mdi-briefcase-upload-outline</v-icon>递交工作记录
					</v-btn>
				</div>
				<div class="d-flex justify-space-between">
					<v-btn
						color="success"
						elevation="0"
						small
						class="mr-1"
						@click="dlgAddWorker = true"
						>
						<v-icon small left>mdi-account-plus</v-icon>添加工人
					</v-btn>
					<v-btn
						color="success"
						elevation="0"
						small
						@click="dlgAddTeam = true"
						>
						<v-icon small left>mdi-account-multiple-plus</v-icon>添加工作团队
					</v-btn>
				</div>
			</div>
		</v-container>
		<v-dialog v-model="dlgSubmitTimesheet" max-width="460">
			<v-card>
				<v-card-title>更新工作记录状态</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="pt-4">
					{{updateStatusMessage}}
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="error" text @click="dlgSubmitTimesheet = false">
						取消
					</v-btn>
					<v-btn color="success" elevation="0" @click="updateTimesheetGroupStatus">
						更新
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--- 添加工人弹出窗口 --->
		<v-dialog 	
			v-model="dlgAddWorker"
			fullscreen
      		hide-overlay
      		transition="dialog-bottom-transition"
			v-if="project && project.id">
				<add-worker-to-timesheet-dialog 
					:projectId="project.id" 
					@on-pop-toast="popToast($event)"
					@on-close="onAddWorkerToTimesheetDialogClose"
					@on-worker-add="addWorkerToTimesheet($event)"
				/>
		</v-dialog>
		<!--- 添加工作团队弹出窗口 --->
		<v-dialog
			v-model="dlgAddTeam"
			fullscreen
    		hide-overlay
      		transition="dialog-bottom-transition"
			v-if="project && project.id">
				<add-team-to-timesheet-dialog
					:projectId="project.id"
					@on-pop-toast="popToast($event)"
					@on-close="onAddTeamToTimesheetDialogClose "
					@on-worker-add="addTeamworkerToTimesheet($event)"
					@on-team-add="addTeamToTimesheet($event)"
				/>
		</v-dialog>

		<!--- Enter Site Mangaer Password --->
		<v-dialog v-model="dlgSiteManager" width="430">
			<v-card>
				<v-card-title>切换工头模式</v-card-title>
				<v-card-subtitle>输入项目工头{{site_manager}}的密码</v-card-subtitle>
				<v-card-text>
					<v-text-field
						outlined
						:type="showPassword ? 'text' : 'password'"
						v-model="SMpassword"
						@click:append="showPassword = !showPassword"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="dlgSiteManager = false;">取消</v-btn>
					<v-btn color="success" elevation="0" :disabled="!SMpassword.length" @click="verifyPassword ">确认</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!----Timesheet编辑窗口--->
		<v-dialog
			v-model="dlgTimesheetForm"
			fullscreen
    		hide-overlay
      		transition="dialog-bottom-transition"
		>
			<timesheet-form
				v-if="project && project.id"
				:workers="selected_workers_for_edit"
				:projectId="project.id"
				:isBulkEdit="isBulkEditTimesheet"
				@on-update-success="popToast($event)"
				@on-close="onCloseTimesheetForm"/>
		</v-dialog>

		<!--- 面部识别确认clock in/clock out 用户窗口 --->
		<v-dialog v-model="dlgConfirmClockDetectedUser" width="480">
			<v-card v-if="activeWorker && activeWorker.user">
				<v-card-title>确认用户: {{activeWorker.user.name}}</v-card-title>
				<v-card-text>
					系统识别了用户<strong>{{activeWorker.user.name}}</strong>
					<template v-if="activeWorker.clock_in_outs.length === 0">
						用户Clock In 数据，点击确认给用户 Clock In。<br/>
						如果这不是您，请点击重新扫描。
					</template>
					<template v-else-if="activeWorker.clock_in_outs.length > 0 && activeWorker.clock_in_outs[0].clock_in !== null && activeWorker.clock_in_outs[0].clock_out === null">
						用户已经 Clock In 了今天的工作，您可以点击确认给用户 Clock Out.<br/>
						如果这不是您，请点击重新扫描。
					</template>
					<template v-else>
						已经收集了用户今日的Clock In 和 Clock Out记录。
					</template>
					
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<template v-if="activeWorker.clock_in_outs[0] && activeWorker.clock_in_outs[0].clock_out !== null  && activeWorker.clock_in_outs[0].clock_in !== null">
						<v-btn color="primary" elevation="0" @click="reCaptureUser">关闭窗口</v-btn>
					</template>
					<template v-else>
						<v-btn color="error" elevation="0" @click="reCaptureUser">重新扫描</v-btn>
						<v-btn color="success" elevation="0" @click="confirmFoundUser">
							确认
						</v-btn>
					</template>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--- Snackbar --->
		<v-snackbar
			fixed
			top
			app
			style="z-index: 999;"
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}
			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- When remove worker confirmation --->
		<confirmation :message="message" :value="dialogWarnning" :type="'warn'" @on-cancel="dialogWarnning = false" @on-confirm="onConfirmWorkerDelete()" />

		<!--- When clock in worker confirmation --->
		<confirmation :message="message" :value="dialogClockIn" :type="'confirm'" @on-cancel="dialogClockIn = false" @on-confirm="clockInWorker()"/>

		<!--- When clock out worker confirmation --->
		<confirmation :message="message" :value="dialogClockOut" :type="'confirm'" @on-cancel="dialogClockOut = false" @on-confirm="clockOutWorker()"/>

		<!--- When change site manager mode confirmation --->
		<confirmation :message="message" :value="dlgDisableSMMode" :type="'confirm'" @on-cancel="dlgDisableSMMode = false" @on-confirm="changeToClockingMode" />

		<!--- When unable to detect user face in the current project --->
		<confirmation :message="message" :value="dlgUserNotFound" :type="'confirm'" @on-cancel="dlgUserNotFound = false" @on-confirm="confirmUserNotFound" />

		<!--- When bulk clock in workers confirmation -->
		<v-dialog v-model="dlgBulkClockIn" width="480">
			<v-card >
				<v-card-title>批量Clock In</v-card-title>
				<v-card-text>
					<template v-if="validClockInList.length">
						<p>以下工人会批量Clock In:</p>
						<ul v-for="worker in validClockInList" :key="worker.id">
							<li>{{ worker.user.name }}</li>
						</ul>
					</template>

					<template v-if="invalidClockInlist.length && validClockInList.length">
						<p class="mt-8">以下工人无法Clock In (已有Clock In 记录):</p>
						<ul v-for="worker in invalidClockInlist" :key="worker.id">
							<li>{{ worker.user.name }}</li>
						</ul>
					</template>

					<template v-if="invalidClockInlist.length && validClockInList.length === 0">
						<p>没有符合条件可以Clock In 的工人</p>
					</template>
					<br />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="error" @click="dlgBulkClockIn = false">取消</v-btn>
					<v-btn color="success" elevation="0" :disabled="invalidClockInlist.length && validClockInList.length === 0 ? true : false" @click="confirmBulkClockIn">确认Clock In</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--- When bulk clock out workers confirmation -->
		<v-dialog v-model="dlgBulkClockOut" width="480">
			<v-card >
				<v-card-title>批量Clock Out</v-card-title>
				<v-card-text>
					<template v-if="validClockOutList.length">
						<p>以下工人会批量Clock Out:</p>
						<ul v-for="worker in validClockOutList" :key="worker.id">
							<li>{{ worker.user.name }}</li>
						</ul>
					</template>

					<template v-if="invalidClockOutlist.length && validClockOutList.length">
						<p class="mt-8">以下工人无法Clock out (已有Clock out 记录或者无 Clock in 记录):</p>
						<ul v-for="worker in invalidClockOutlist" :key="worker.id">
							<li>{{ worker.user.name }}</li>
						</ul>
					</template>

					<template v-if="invalidClockOutlist.length && validClockOutList.length === 0">
						<p>没有符合条件可以Clock Out 的工人</p>
					</template>
					<br />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="error" @click="dlgBulkClockOut = false">取消</v-btn>
					<v-btn color="success" elevation="0" :disabled="invalidClockOutlist.length && validClockOutList.length === 0 ? true : false" @click="confirmBulkClockOut">确认Clock Out</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--- When bulk edit timesheet and workers missing clock in time -->
		<v-dialog v-model="dlgMissingClockInData" width="480">
			<v-card>
				<v-card-title>
					<v-icon color="orange" class="mr-2">mdi-alert</v-icon>批量编辑工时数据检测注意
					<v-spacer></v-spacer>
					<v-btn icon @click="dlgMissingClockInData = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<br />
					<p>只能编辑拥有 Clock In 数据的用户。 <br />
					以下用户缺少 Clock In 数据，</p>
					<ul>
						<li v-for="worker in missingClockInDataWorkers" :key="worker.id">
							<strong>{{ worker.user.name }}</strong>
						</li>
					</ul>
					<br />
					<p>
						您可以选择：
					</p>
					<ol>
						<li><strong>Clock In 并编辑</strong> - 自动Clock In以上用户并开始编辑工时</li>
						<li><strong>忽略并继续编辑</strong> - 忽略以上用户只编辑包含Clock In数据的工人</li>
					</ol>
					<br />
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="success" elevation="0" @click="excludeAndProceed">忽略并继续编辑</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="success" elevation="0" @click="clockInAndProcced">Clock In并编辑</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--- When bulk delete workers confirmation --->
		<confirmation :message="message" :value="dlgBulkDeleteWorker" :type="'confirm'" @on-cancel="dlgBulkDeleteWorker = false" @on-confirm="confirmBulkRemoveWorker" />
	</v-sheet>
</template>

<script>
import TimesheetService from '../../services/Timesheet';
import WorkTeamService from '../../services/WorkTeam';
import UserService from '../../services/User';
import { format, parse } from 'date-fns';
import { mapState, mapActions } from 'vuex';
import addWorkerToTimesheetDialog from '../../components/Timesheet/addWorkerToTimesheetDialog.vue';
import addTeamToTimesheetDialog from '../../components/Timesheet/addTeamToTimesheetDialog.vue';
import workerCard from '../../components/Timesheet/workerCard.vue';
import confirmation from '../../components/common/confirmation.vue';
import timesheetForm from '../../components/Timesheet/timesheetForm.vue';
import faceRecognition from '../../components/FaceRecognition/faceRecognition.vue';

export default {
	name: 'viewTimesheetProject',
	components: {
		addWorkerToTimesheetDialog,
		addTeamToTimesheetDialog,
		workerCard,
		confirmation,
		timesheetForm,
		faceRecognition
	},
	created(){
		this.group_id = this.$route.params.id;
		this.getProjectInfo();
		this.getTimesheetGroup();
	},
	data: () => ({
		group_id: null,
		project: null,
		timesheet_date: null,
		teams: [],
		existingTeams: [],
		//new array for new clock in/out uI
		teams_timeheet: [],
		workers_timesheet: [],
		search: '',
		dlgAddTeam: false,
		dlgAddWorker: false,
		dlgSubmitTimesheet: false,
		dialogWarnning: false,
		dialogClockIn: false,
		dialogClockOut: false,
		dlgSiteManager: false,
		dlgDisableSMMode: false,
		dlgTimesheetForm: false,
		dlgBulkClockIn: false,
		dlgBulkClockOut: false,
		dlgBulkDeleteWorker: false,
		dlgMissingClockInData: false,
		dlgUserNotFound: false,
		dlgConfirmClockDetectedUser: false,
		missingClockInDataWorkers: [],
		SMpassword: '',
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		timesheet_status: null,
		loading: false,
		updateStatusMessage: '',
		message: '',
		activeWorker: null,
		activeTeam: null,
		isBulkEditMode: false,
		selected_workers_for_edit: [],
		searchResult: [],
		validClockOutList: [],
		invalidClockOutlist: [],
		validClockInList: [],
		invalidClockInlist: [],
		filter: undefined,
		showPassword: false,
		site_manager: '',
		isBulkEditTimesheet: false,
	}),
	computed: {
		displayTimesheetDate(){
			return `${this.timesheet_date}项目工作记录`;
		},
		...mapState('user', ['status',  'user', 'site_manager_mode']),
		canUserEditTimesheet(){
			if( this.user && this.user.role === 'admin' ){
				return true;
			} else {
				return this.timesheet_status === 0 ? true : false;

			}
		},
		getDayLabourNumberTotal(){
			return this.teams.map(team=>{
					return team.day_labour.count;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayLabourHourTotal(){
			return this.teams.map(team=>{
					return team.day_labour.total_hours;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayPackageNumberTotal(){
			return this.teams.map(team=>{
					return team.package.count;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayPackageHoursrTotal(){
			return this.teams.map(team=>{
					return team.package.total_hours;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getNumberOfTeams(){
			return this.teams.length;
		}
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		},
		dlgSiteManager(){
			this.SMpassword = '';
		}
	},
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	methods: {
		async getProjectInfo(){
			try {
				let response = await TimesheetService.viewTimesheetDayEntry(this.group_id);
				if(response.data.statusCode === 200){
					this.project = response.data.data.project;
					this.site_manager = response.data.data.project.site_managers.length > 0 ? response.data.data.project.site_managers[0].name : '';
					this.timesheet_status = parseInt(response.data.data.status);

					const date = parse(response.data.data.date, 'dd/mm/yyyy', new Date());
					
					this.timesheet_date = format(date, 'yyyy年MM月dd日');
				}
			} catch(error){
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async getTimesheetGroup(){
			try {
				let response = await TimesheetService.getTimeSheetGroupV2(this.group_id);
				if(response.data.statusCode === 200){
					this.teams_timeheet = response.data.data
				}
			} catch(error){
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async assignTeamToTimesheet(team){
			const body = {
				"timesheet_group_id": this.group_id,
				"team_id": team.id,
			}
			try {
				let response = await TimesheetService.createTimesheetWorkTeam(body);
				if(response.data.statusCode === 200){
					//this.dlgAddTeam = false;
					this.loading = true;
					this.popToast({	message: `团队${response.data.message}`, type: 'success'})
					this.getTimesheetGroup();
				}
			} catch(error){
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async deleteTeamFromTimesheet(teamId){
			try {
				let response = await TimesheetService.deleteTimesheetWorkTeam(teamId);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.popToast({	message: `团队${response.data.message}`, type: 'success'})
					this.getTimesheetGroup();
				}
			} catch(error){
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		updateTimesheet(statusCode){
			if(statusCode === 1){
				this.dlgSubmitTimesheet = true;
				this.updateStatusMessage = `注意！请确认当日工作记录正确，工作记录递交之后您将不能再进行修改。`;
			} else if( statusCode === 2 ){
				this.dlgSubmitTimesheet = true;
				this.updateStatusMessage = `您确认当日的工作记录正确且通过审核吗？`;
			}
		},
		async updateTimesheetGroupStatus(){
			try {
				let newStatus = ( this.user && this.user.role === 'admin' && this.timesheet_status === 1 ) ?  2 : 1;
				let body = {
					"status": newStatus
				}
				let response = await TimesheetService.editDayEntry(this.group_id, body);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.popToast({	message: `工作记录${response.data.message}`, type: 'success'})
					this.getTimesheetGroup();
					this.dlgSubmitTimesheet = false;
					this.timesheet_status = response.data.data.status;
				}
			} catch(error){
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async removeTimesheetGroup(team){
			try {
				let response = await TimesheetService.deleteGroup(team.id);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.popToast({	message: `团队${team.name}删除成功`, type: 'success'})
					this.getTimesheetGroup();
				}
			} catch(error){
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		onDeleteGroup(team){
			if(team.members.length){
				//You have members in this team
				this.popToast({	message: `当前工作团队中仍有工作人员，请删除组内工人后再删除工作组。`, type: 'info'})
			} else {
				this.removeTimesheetGroup(team)
			}
		},
		popToast(toast,time=500){
			if (!toast) {
				return
			}
			this.snackbar.message = toast.message;
					this.snackbar.color = toast.type;
					setTimeout(()=>{
						this.snackbar.model = true;
					}, time)
		},
		addWorkerToTimesheet(worker) {
			const memberId = worker.id
			if (this.teams_timeheet.length){
				const ungroupExist = this.teams_timeheet.filter((team)=>{
					return team.related_id === null
				})

				if (ungroupExist.length){
					this.addMemberToGroup(ungroupExist[0].id, memberId);
					this.getTimesheetGroup();
				} else {
					console.log('missing ungroup')
				}

			} else {

				this.createGroupWithOneMember(0, memberId)
				console.log('no group exist, cerat the group first')
			}

		},
		addTeamworkerToTimesheet(workTeam) {
			const teamId = workTeam.team.id;
			const memberId = workTeam.worker.id;

			const teamExist = this.teams_timeheet.filter((team)=>{
				return team.related_id === teamId;
			})

			if (teamExist.length) {
				//if team group is exist. 
				const groupId = teamExist[0].id;
				this.addMemberToGroup(groupId, memberId)
				this.getTimesheetGroup();
			} else {
				this.createGroupWithOneMember(teamId, memberId)
			}
		},
		onAddTeamToTimesheetDialogClose() {
			this.dlgAddTeam = false; 
			location.reload()
		},
		onAddWorkerToTimesheetDialogClose(){
			this.dlgAddWorker = false;
			location.reload()
		},
		async addMemberToGroup(groupId, memberId){
			try {
				let response = await TimesheetService.createGroupMember(groupId, memberId);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.popToast({	message: `用户成功添加`, type: 'success'})
				}
			} catch(error){
				if(error.status === 422) {
					this.popToast({	message: `用户已经在团队中，请勿重复添加。`, type: 'info'})
				} else {
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
				}
			}
		},
		addTeamToTimesheet (team){
			team.collapsed = false;
			const workTeamId = team.id

			const isGroupExist = this.teams_timeheet.filter((team)=>{
				return team.related_id === workTeamId;
			})
			
			if (isGroupExist.length === 0){
				//fetch all the worker first 
				this.createGroupWithAllMembers(workTeamId)
			} else {
				this.popToast({	message: `团队已经添加，请勿重复添加。`, type: 'info'})
			}
		},
		async getTeamDetail(entryGroupId, team_id){
			try {
				let response = await WorkTeamService.getWorkTeam(team_id);
				const team = response.data.data;
				if(response.data.statusCode === 200){
					if(team.workers.length){
						team.workers.forEach((worker)=>{
							this.addMemberToGroup(entryGroupId, worker.id)
						})
						this.getTimesheetGroup();
					}
				}
			} catch(error) {;
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async createGroupWithOneMember(workTeamId, memberId){
			//add team member to timesheet before group created
			try {
				let response = await TimesheetService.createGroup(this.group_id, workTeamId);
				if(response.data.statusCode === 200){
					this.loading = true;
					const entryGroupId = response.data.data.id;

					this.addMemberToGroup(entryGroupId, memberId)
					this.popToast({	message: `团队成功添加。`, type: 'success'})
					this.getTimesheetGroup();
				}
			} catch(error){
				if(error.status === 422) {
					this.popToast({	message: `团队已经添加，请勿重复添加。`, type: 'info'})
				} else {
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
				}
			}
		},
		async createGroupWithAllMembers(workTeamId){
			//add group and all members to timesheet.
			try {
				let response = await TimesheetService.createGroup(this.group_id, workTeamId);
				if(response.data.statusCode === 200){
					this.loading = true;
					const entryGroupId = response.data.data.id;
					this.getTeamDetail(entryGroupId, workTeamId);
					this.getTimesheetGroup();
					this.popToast({	message: `团队成功添加。`, type: 'success'})
				}
			} catch(error){
				if(error.status === 422) {
					this.popToast({	message: `团队已经添加，请勿重复添加。`, type: 'info'})
				} else {
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
				}
			}
		},
		clearWorkerSearch() {
			this.search = ''
			this.searchResult = [];
			console.log(
				this.selected_workers_for_edit)
		},
		onSearchWorker() {
			let result = [];
			this.filter = undefined;
			this.teams_timeheet.forEach((team)=>{
				team.members.forEach((worker)=>{
					result.push(worker)
				})
			})

			const searchResult = result.filter((worker)=>{
				return worker.user.name.includes(this.search);
			})
			this.searchResult = searchResult
		},
		onWorkerClockOut(worker) {
			this.dialogClockOut = true;
			this.message = `Clock Out工人 - ${worker.user.name}？`;
			this.activeWorker = worker;
		},
		onWorkerClockIn(worker) {
			this.dialogClockIn = true;
			this.message = `Clock In 工人 - ${worker.user.name}？`;
			this.activeWorker = worker;
		},
		onWorkerRemove(worker) {
			this.dialogWarnning = true;
			this.message = `您确定要把${worker.user.name}从项目中删除吗？`;
			this.activeWorker = worker;
		},
		async onConfirmWorkerDelete(isBulk = false, worker, isBulkProcesComplete = false ){
			let _worker = isBulk ? worker : this.activeWorker;
			try {
				let response = await TimesheetService.deleteGroupMember(_worker.id);
				if(response.data.statusCode === 200){
					if (isBulk) {
						if (isBulkProcesComplete){
							this.popToast({	message: `批量删除工人成功`, type: 'success'})
							setTimeout(()=>{ location.reload() }, 2000)
						}
					} else {
						this.dialogWarnning = false;
						this.popToast({	message: `${this.activeWorker.user.name} 成功从当日项目中删除`, type: 'success'})
						this.getTimesheetGroup();

						//process when under search result list
						if(this.search && this.search.length && this.searchResult.length){
							const deleteIndex = this.searchResult.findIndex((worker)=> worker.id === _worker.id)
							this.searchResult.splice(deleteIndex, 1);
						}
					}

					
				}
			} catch(error) {
				this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async clockInWorker(isBulk = false, worker, isBulkProcesComplete = false ){
			let _worker = isBulk ? worker : this.activeWorker;
			try {
				let response = await TimesheetService.memberClockIn(_worker.id);
				if(response.data.statusCode === 200){
					if (isBulk) {
						if (isBulkProcesComplete){
							this.popToast({	message: `批量 clock in 工人成功`, type: 'success'})
							setTimeout(()=>{ location.reload() }, 2000)
						}
					} else {
						this.loading = true;
						this.popToast({	message: `${_worker.user.name} clock in 成功`, type: 'success'})
						this.getTimesheetGroup()
						this.dialogWarnning = false;
						this.dialogClockIn = false;
					}

				}
			} catch(error){
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		async clockOutWorker(isBulk = false, worker, isBulkProcesComplete = false ){
			let _worker = isBulk ? worker : this.activeWorker;
			try {
				let response = await TimesheetService.memberClockOut(_worker.id);
				if(response.data.statusCode === 200){
					if (isBulk) {
						if (isBulkProcesComplete){
							this.popToast({	message: `批量 clock out 工人成功`, type: 'success'})
							setTimeout(()=>{ location.reload() }, 2000)
						}
					} else {
						this.loading = true;
						this.popToast({	message: `${this.activeWorker.user.name} clock out 成功`, type: 'success'})
						this.dialogWarnning = false;
						this.dialogClockOut = false;
						this.getTimesheetGroup()
					}

				}
			} catch(error){
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		onChangeMode(){
			if( this.site_manager_mode) {
				//prompt to check password
				this.message = '确定切换回打卡模式吗？打卡模式下无法编辑工人工作信息.'
				this.dlgDisableSMMode = true
				
			} else {
				//prompt to confirm to exist site manager mode
				this.dlgSiteManager = true;
			}
		},

		...mapActions('user', ['changeSiteManagerMode']),
		changeToClockingMode(){
			this.dlgDisableSMMode = false;
			this.changeSiteManagerMode(false);
			this.isBulkEditMode = false;
			this.selected_workers_for_edit =[];
			location.reload()
		},
		changeToSiteManagerMode(state) {
			this.changeSiteManagerMode(state);
			this.dlgSiteManager = false;
		},
		async verifyPassword(){
			try {
				let response = await UserService.verifyPassword(this.SMpassword);
				if(response.data.statusCode === 200){
					this.popToast({	message: response.data.message, type: 'success'})
					this.changeToSiteManagerMode(true)
				} else {
					this.popToast({	message: '密码验证失败', type: 'error'})
				}
			} catch(error){
				this.popToast({	message: '密码验证失败', type: 'error'})
			}
		},
		bulkEditWorkerTimesheet() {
			if (this.selected_workers_for_edit.length){
				console.log(this.selected_workers_for_edit)
				this.missingClockInDataWorkers = [];
				this.missingClockInDataWorkers = this.selected_workers_for_edit.filter( (worker)=>{
					return worker.clock_in_outs.length === 0;
				})
				if (this.missingClockInDataWorkers.length){
					this.dlgMissingClockInData = true;
					// prompt dialog notify user, the following users missing are missing clock in data, and won't be added for bulk edit, please clock them in first. Remove user without clock in data. 
				} else {
					this.isBulkEditTimesheet = true;
					this.dlgTimesheetForm = true;
				}
			} 
		},
		excludeAndProceed() {
			for (let i = this.selected_workers_for_edit.length - 1; i >= 0; i--) {
				if (this.missingClockInDataWorkers.includes(this.selected_workers_for_edit[i])) {
					this.selected_workers_for_edit.splice(i, 1);
				}
			}
			this.isBulkEditTimesheet = true;
			this.dlgTimesheetForm = true;
		},
		async clockInAndProcced() {
			this.dlgMissingClockInData = false;
			this.loading = true;
			this.validClockInList = []
			this.validClockInList  = this.missingClockInDataWorkers;
			this.validClockInList.forEach((worker, index)=>{
					this.quickClockInWorker(worker, this.validClockInList.length === Number(index) + 1 ? true : false)
			})

	 	},
		 async quickClockInWorker(worker, isBulkProcesComplete = false ){
			try {
				let response = await TimesheetService.memberClockIn(worker.id);
				if(response.data.statusCode === 200){
						this.loading = true;
						this.dialogWarnning = false;
						this.dialogClockIn = false;
						if (isBulkProcesComplete){
							this.popToast({	message: `批量 clock in 工人成功`, type: 'success'})
							
							this.refetchTimesheetGroup().then(()=>{
								this.updateWorkerList();
							}).then(()=>{
								this.isBulkEditTimesheet = true;
								this.dlgTimesheetForm = true;
							})
						}
				}
			} catch(error){
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
			}
		},
		refetchTimesheetGroup(){
			return new Promise((resolve) =>{
				this.getTimesheetGroup();
				setTimeout(()=>{ 
					resolve();
				}, 500)
			})
		},
		updateWorkerList() {
			const updatedTimesheetList = []
			this.teams_timeheet.forEach((group)=> {
				group.members.forEach((worker)=>{
					updatedTimesheetList.push(worker)
				})
			})

			this.selected_workers_for_edit.forEach((worker)=> {
				updatedTimesheetList.forEach((_worker)=>{
					if(_worker.id === worker.id) {
						worker.clock_in_outs = _worker.clock_in_outs;
					}
				})
			})
		},
 		bulkClockOut(){
			if (this.selected_workers_for_edit.length){
				this.validClockOutList = []
				this.invalidClockOutlist = []
				
				this.selected_workers_for_edit.forEach((worker)=>{
					if (worker.clock_in_outs.length === 0) {
						this.invalidClockOutlist.push(worker)
					}
					if (worker.clock_in_outs[0] && worker.clock_in_outs[0].clock_out === null) {
						this.validClockOutList.push(worker)
					}
					if (worker.clock_in_outs[0] && worker.clock_in_outs[0].clock_out !== null  && worker.clock_in_outs[0].clock_in !== null  ) {
						this.invalidClockOutlist.push(worker)
					}
				})
				
				this.dlgBulkClockOut = true;
			}
		},
		confirmBulkClockOut() {
			if( this.validClockOutList.length){
				this.validClockOutList.forEach((worker, index)=>{
					this.clockOutWorker(true, worker,  this.validClockOutList.length === Number(index) + 1 ? true : false);
				})
				this.dlgBulkClockOut = false;
			}
		},
		bulkClockIn(){
			if (this.selected_workers_for_edit.length){
				this.validClockInList = []
				this.invalidClockInlist = []
				this.selected_workers_for_edit.forEach((worker)=>{
					if (worker.clock_in_outs.length) {
						this.invalidClockInlist.push(worker)
					} else {
						this.validClockInList.push(worker)
					}
				})
				//console.log(this.invalidClockInlist)
				//console.log(this.validClockInList)
				this.dlgBulkClockIn = true;
			}
		},
		confirmBulkClockIn() {
			if(this.validClockInList.length){
				this.validClockInList.forEach((worker, index)=>{
					this.clockInWorker(true, worker, this.validClockInList.length === Number(index) + 1 ? true : false)
				})
				this.dlgBulkClockIn = false;
			}
		},
		bulkRemoveWorker() {
			if (this.selected_workers_for_edit.length){
				this.message = '确认批量从当日的工作记录中删除选择的工人吗？删除后将无法恢复数据。'
				this.dlgBulkDeleteWorker = true;
			}
		},
		confirmBulkRemoveWorker(){
			if (this.selected_workers_for_edit.length){
				this.selected_workers_for_edit.forEach((worker, index)=>{
					this.onConfirmWorkerDelete(true, worker, this.selected_workers_for_edit.length === Number(index) + 1 ? true : false)
				})
				this.dlgBulkDeleteWorker = false;
			}
		},
		onEditWorkerTimesheet(worker){
			this.selected_workers_for_edit = [];
			this.viewGroupMember(worker.id)
			this.dlgTimesheetForm = true;
		},
		async viewGroupMember(groupMemberId){
			try {
				let response = await TimesheetService.viewGroupMember(groupMemberId);
				if(response.data.statusCode === 200){
					this.selected_workers_for_edit.push(response.data.data)
				}
			} catch(error){
				if(error.status === 422) {
					this.popToast({	message: `团队已经添加，请勿重复添加。`, type: 'info'})
				} else {
					this.popToast({	message: `错误信息：${error.data.message}`, type: 'error'})
				}
			}
		},
		bulkEdit(){
			this.filter = undefined;
			this.isBulkEditMode = !this.isBulkEditMode;
		},
		onWorkerSelectionChange(worker){
			const isSelectedIndex = this.selected_workers_for_edit.findIndex((member)=>member.id === worker.id);
			if(isSelectedIndex === -1){
				this.selected_workers_for_edit.push(worker)
			} else {
				this.selected_workers_for_edit.splice(isSelectedIndex, 1);
			}
		},
		onGroupSelected(workers) {
			workers.forEach((worker)=>{
				const isSelectedIndex = this.selected_workers_for_edit.findIndex((member)=>member.id === worker.user.id)
				if(isSelectedIndex === -1){
					this.selected_workers_for_edit.push(worker.user)
				} else {
					this.selected_workers_for_edit.splice(isSelectedIndex, 1);
				}
			})
		},
		isWorkerClockedIn(clockInOutList){
			if (clockInOutList && clockInOutList.length){
				if (clockInOutList[0].clock_in === null && clockInOutList[0].clock_out === null ){
					return 0
				} 

				if (clockInOutList[0].clock_in.length > 0 && clockInOutList[0].clock_out === null ){
					return 1
				}
				if (clockInOutList[0].clock_in.length > 0 && clockInOutList[0].clock_out.length > 0 ){
					return 2
				}

			} else {
				return 0
			}
		},
		showFilter(){
			this.searchResult = [];

			if (this.filter === undefined) {
				return;	
			}

			let result = [];
			this.teams_timeheet.forEach((team)=>{
				team.members.forEach((worker)=>{
					result.push(worker)
				})
			})
			if (this.filter === 'clockInOnly') {
				this.searchResult = result.filter((worker)=>{
					return worker.clock_in_outs.length > 0 && worker.clock_in_outs[0].clock_in !== null && worker.clock_in_outs[0].clock_out === null;
				})
			}

			if (this.filter === 'allCompleted') {
				this.searchResult = result.filter((worker)=>{
					return worker.clock_in_outs.length && worker.clock_in_outs[0].clock_in !== null && worker.clock_in_outs[0].clock_out !== null;
				})
			}
			if (this.filter === 'needClockIn') {
				this.searchResult = result.filter((worker)=>{
					return worker.clock_in_outs.length == 0;
				})
			}
			
		},
		onCloseTimesheetForm() {
			location.reload()
		},
		checkUserStatus(user){
			this.dlgConfirmClockDetectedUser = true;

			let allTimesheetUsers;
			this.teams_timeheet.forEach((group)=>{
				allTimesheetUsers = [...group.members]
			})
			const foundUser = allTimesheetUsers.filter((member)=>{
				return member.user.id === user.id
			})
			this.activeWorker=  foundUser[0]
		},
		reCaptureUser(){
			this.dlgConfirmClockDetectedUser = false;
			this.$refs.faceRecognition.resetCapture()
		},
		confirmFoundUser(){
			if (this.activeWorker.clock_in_outs.length === 0){
				this.clockInWorker()
			} else {
				this.clockOutWorker()
			}
			this.$refs.faceRecognition.resetCapture()
			this.dlgConfirmClockDetectedUser = false;
		},
		onUserNotFound(message) {
			this.message = message;
			this.dlgUserNotFound = true;
		},
		confirmUserNotFound(){
			this.dlgUserNotFound = false;
			this.$refs.faceRecognition.resetCapture()
		}
	},
}
</script>
<style scoped>
	.fixedSaveButton {
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		background: #fff;
		padding-bottom: 32px;
	}
	.bordered {
		border: 1px solid #ccc;
	}
</style>
